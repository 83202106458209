import { IonImg, IonSlide, IonSlides } from '@ionic/react';
import { useCallback, useEffect, useState } from 'react';
import DataService from '../../services/DataService';
import './ActionCardsSlides.css';

interface ActionCardsProps {
}

const ActionCardsSlides: React.FC<ActionCardsProps> = () => {

  const [actionCards, setActionCards] = useState([]);
  
  const fetchActionCards = useCallback(async () => {
    const response = await DataService.fetchActionCards();
    setActionCards(response.data.actionCards);
  }, []);

  useEffect(() => {
    fetchActionCards();
  });

  const slideOpts = {
  };
  
  
  return (
    <div className="action-cards">
      <IonSlides options={slideOpts}>
        {actionCards.map((actionCard: any) => {
          return <IonSlide key={actionCard.id}>
            <div className="slide">
              <IonImg src={actionCard.imageSrc} alt={actionCard.title}></IonImg>
              <h2>{actionCard.title}</h2>
              <p>{actionCard.description}</p>
            </div>
          </IonSlide>
        })}
      </IonSlides>
    </div>
  );
};

export default ActionCardsSlides;
