import { makeRequest } from "./Request";
import { Resources } from "./Resources";

export default class DataService {
    public static async fetchPortfolioValue(): Promise<any> {
        try {
            // mock value
            return new Promise((resolve) => {
                resolve({
                    data: {
                        netWorth: '$100',                     
                    }
                });
            });
            
            // return await makeRequest({
            //     method: 'GET',
            //     url: `${Resources.PORTFOLIO_VALUE}`,
            // });
        } catch (err) {
            // handle error
        }
    }

    public static async fetchActionCards(): Promise<any> {
        try {
            // mock value
            return new Promise((resolve) => {
                resolve({
                    data: {
                        actionCards: [
                            {
                                "id": 1,
                                "imageSrc": "https://ionicframework.com/docs/demos/api/slides/slide-1.png",
                                "title": "Microsoft Corp.",
                                "description": "Technology",
                                "action": ""
                            },
                            {
                                "id": 2,
                                "imageSrc": "https://ionicframework.com/docs/demos/api/slides/slide-2.png",
                                "title": "Apple Inc.",
                                "description": "Technology",
                                "action": ""
                            },
                            {
                                "id": 3,
                                "imageSrc": "https://ionicframework.com/docs/demos/api/slides/slide-3.png",
                                "title": "Akamai",
                                "description": "Technology",
                                "action": ""
                            },
                            {
                                "id": 4,
                                "imageSrc": "https://ionicframework.com/docs/demos/api/slides/slide-4.png",
                                "title": "Netflix",
                                "description": "Technology",
                                "action": ""
                            },
                        ]
                    }
                });
            });

            // return await makeRequest({
            //     method: 'GET',
            //     url: `${Resources.ACTION_CARDS}`,
            // });
        } catch (err) {
            // handle error
        }
    }

}
