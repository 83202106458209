import { IonButton } from '@ionic/react';
import { useCallback, useEffect, useState } from 'react';
import DataService from '../../services/DataService';
import './PortfolioValueContainer.css';

interface ContainerProps {
}

const PortfolioValueContainer: React.FC<ContainerProps> = () => {

  const [netWorth, setNetWorth] = useState('-');

  const fetchNetWorth = useCallback(async () => {
    const response = await DataService.fetchPortfolioValue();
    setNetWorth(response.data.netWorth);
  }, []);

  useEffect(() => {
    fetchNetWorth();
  });

  return (
    <div className="user-portfolio-container">
      <p className="title">Total Worth</p>
      <p className="value">{netWorth}</p>
      <div className="action">
        <IonButton color="tertiary">Invest Now</IonButton>
        <IonButton color="light">Deposit USD</IonButton>
      </div>
    </div>
  );
};

export default PortfolioValueContainer;
